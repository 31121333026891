export const SQUARE = '1:1';
const FILL_COLOR = 'e1e3e6';

export const IMAGES_BREAKPOINTS = {
  DESKTOP_XL: 1600,
  DESKTOP_L: 1375,
  DESKTOP_M: 1200,
  DESKTOP_S: 1024,
  TABLET_L: 800,
  TABLET_M: 640,
  MOBILE_L: 480,
  MOBILE_M: 400,
  MOBILE_S: 320,
};

export const IMAGES_DEFAULT_BREAKPOINTS = Object.values(IMAGES_BREAKPOINTS);

export function addCropParam(imageUrl, crop = 'edges') {
  try {
    const urlWithQueryParams = new URL(imageUrl);

    urlWithQueryParams.searchParams.set('fit', 'crop');
    urlWithQueryParams.searchParams.set('crop', crop);

    return urlWithQueryParams.href;
  } catch {
    return imageUrl;
  }
}

export function addFocalPointParam(imageUrl, fpX, fpY) {
  try {
    const urlWithQueryParams = new URL(imageUrl);

    urlWithQueryParams.searchParams.set('fp-x', fpX);
    urlWithQueryParams.searchParams.set('fp-y', fpY);

    return urlWithQueryParams.href;
  } catch {
    return imageUrl;
  }
}

export function addFormatParam(imageUrl, format = 'auto') {
  try {
    const urlWithQueryParams = new URL(imageUrl);

    if (format === 'auto') {
      urlWithQueryParams.searchParams.set('auto', 'format,compress');
    } else {
      urlWithQueryParams.searchParams.set('auto', 'compress');
      urlWithQueryParams.searchParams.set('fm', format);
    }

    return urlWithQueryParams.href;
  } catch {
    return imageUrl;
  }
}

export const addParam = (imageUrl, paramName, paramValue) => {
  try {
    const urlWithQueryParams = new URL(imageUrl);

    urlWithQueryParams.searchParams.set(paramName, paramValue);

    return urlWithQueryParams.href;
  } catch {
    return imageUrl;
  }
};

export function addPlaceholderParams(imageUrl, width, auto = 'format') {
  try {
    const urlWithQueryParams = new URL(imageUrl);

    const px = width > 800 ? 25 : 10;
    const blur = width > 800 ? 300 : 200;

    urlWithQueryParams.searchParams.set('blur', blur);
    urlWithQueryParams.searchParams.set('px', px);
    urlWithQueryParams.searchParams.set('auto', auto);
    urlWithQueryParams.searchParams.set('q', 20);

    return urlWithQueryParams.href;
  } catch {
    return imageUrl;
  }
}

export function getOptimizedImage(
  imageUrl,
  {
    width,
    aspectRatio,
    crop,
    fpX,
    fpY,
    height,
    fit,
    fill,
    fillColor,
    quality = 60,
  },
) {
  if (!imageUrl) return imageUrl;

  let imageWithQueryParams = imageUrl;

  if (width) {
    imageWithQueryParams = addParam(imageWithQueryParams, 'width', width);
  }

  if (aspectRatio) {
    imageWithQueryParams = addParam(imageWithQueryParams, 'ar', aspectRatio);
  }

  if (height) {
    imageWithQueryParams = addParam(imageWithQueryParams, 'height', height);
  }

  if (
    (aspectRatio || width || crop) &&
    (!imageUrl.includes('crop=') || imageUrl.includes('crop=edges'))
  ) {
    imageWithQueryParams = addCropParam(imageWithQueryParams, crop);
  }

  if (fit) {
    imageWithQueryParams = addParam(imageWithQueryParams, 'fit', fit);
  }

  if (fill) {
    imageWithQueryParams = addParam(imageWithQueryParams, 'fill', fill);
  }

  if (fillColor) {
    imageWithQueryParams = addParam(
      imageWithQueryParams,
      'fill-color',
      fillColor,
    );
  }

  if (fpX && fpY) {
    imageWithQueryParams = addFocalPointParam(imageWithQueryParams, fpX, fpY);
  }

  imageWithQueryParams = addFormatParam(imageWithQueryParams);
  imageWithQueryParams = addParam(imageWithQueryParams, 'q', quality);

  return imageWithQueryParams;
}

export const getOpenGraphImage = (src, pageType) => {
  if (pageType === 'lookbook') {
    const urlWithQueryParams = new URL(src);

    return getOptimizedImage(src, {
      width: 1200,
      aspectRatio: urlWithQueryParams.searchParams.get('ar'),
      crop: urlWithQueryParams.searchParams.get('crop'),
      fpX: urlWithQueryParams.searchParams.get('fp-x'),
      fpY: urlWithQueryParams.searchParams.get('fp-y'),
      height: 630,
      fit: 'crop',
      fill: 'solid',
      fillColor: FILL_COLOR,
    });
  }

  if (pageType === 'product') {
    return getOptimizedImage(src, {
      width: 1200,
      height: 630,
      fit: 'fillmax',
      fill: 'solid',
      fillColor: FILL_COLOR,
    });
  }

  return getOptimizedImage(src, {
    width: 1200,
    height: 630,
  });
};
