import { arrayOf, oneOfType, string, number, bool } from 'prop-types';
import { shape, or, explicitNull } from 'airbnb-prop-types';

export const cmsBaseBlockShape = shape({
  id: string.isRequired,
  type: string.isRequired,
  value: oneOfType([string, shape({})]).isRequired,
});

export const cmsBodyType = or([explicitNull, cmsBaseBlockShape]);

export const cmsPageImgShape = shape({
  url: string,
  title: string,
  altText: string,
  width: number,
  height: number,
});

export const cmsPageShape = shape({
  id: number.isRequired,
  meta: shape({
    metaTitle: string.isRequired,
    metaDescription: string.isRequired,
    metaImage: cmsPageImgShape,
    htmlUrl: string.isRequired,
    langUrls: shape({
      de: string,
      fr: string,
    }),
  }).isRequired,
  title: string.isRequired,
  image: or([explicitNull(), cmsPageImgShape]).isRequired,
  lead: or([explicitNull(), string]).isRequired,
  body: or([explicitNull(), arrayOf(cmsBaseBlockShape)]).isRequired,
});

export const menuItemShape = shape({
  id: number.isRequired,
  title: string.isRequired,
  linkUrl: string.isRequired,
});

export const menuItemsType = arrayOf(menuItemShape);

export const videoShape = shape({
  stream: string,
  fallback: string,
});

export const cmsComponentData = shape({
  componentId: string,
  results: arrayOf(shape({})),
  isError: bool,
});

export const cmsComponentsData = arrayOf(cmsComponentData);
