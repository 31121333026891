import styled, { css } from 'styled-components';

import { media } from '@utils/styled';

export const VideoWrapper = styled.div`
  position: relative;
  z-index: 0;
  display: ${({ isDesktopVideo }) => (isDesktopVideo ? 'none' : 'block')};
  height: 100%;

  ${media.from640up`
    display: ${({ isDesktopVideo }) => (isDesktopVideo ? 'block' : 'none')};
  `};
`;

export const AspectRatioWrapper = styled.div`
  @supports not (aspect-ratio: 1/1) {
    position: relative;
    display: block;
    width: 100%;

    &::before {
      content: '';
      float: left;
    }

    &::after {
      content: '';
      clear: both;
      display: block;
    }

    picture {
      position: ${({ disableAbsolute }) =>
        disableAbsolute ? 'static' : 'absolute'};
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  ${({ breakpointsWithAspectRatios }) => {
    const lastIndex = breakpointsWithAspectRatios.length - 1;

    return breakpointsWithAspectRatios
      .map(([breakpoint, aspectRatio], index, arrayPointer) => {
        const [breakpointMaxWidth] =
          arrayPointer[Math.min(index + 1, lastIndex)];
        const aspectRatioRule = `aspect-ratio: ${aspectRatio.replace(':', '/')}`;

        const [aspectWidth, aspectHeight] = aspectRatio
          .split(':')
          .map(parseFloat);
        const aspectRatioPercent = aspectHeight / aspectWidth;

        return `
            ${index === 0 ? '' : `@media (min-width: ${breakpoint}px) {`}
              @supports not (${aspectRatioRule}) {
                &::before {
                  padding-top: ${(aspectRatioPercent * 100).toFixed(2)}%;
                }
              }

              img {
                width: ${breakpointMaxWidth}px;
                ${aspectRatioRule};
              }
              ${index === 0 ? '' : '}'}
          `;
      })
      .join('\n');
  }}

  ${({ withVideo }) =>
    withVideo &&
    css`
      overflow: hidden;
      width: 100%;

      & img {
        height: 100%;
        object-fit: cover;
        object-position: center;

        ${media.from640up`
          display: inline;
        `};
      }
    `}
`;
