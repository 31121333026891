export const selectFavoritesIdsAuthenticated = state =>
  state.favorites.resultsAuthenticated;
export const selectFavoritesIdsUnauthenticated = state =>
  state.favorites.resultsUnauthenticated;
export const selectIsFavoritesFetching = state => state.favorites.isFetching;
export const selectIsFeatureDiscoveryVisible = state =>
  state.favorites.isFeatureDiscoveryVisible;
export const selectFavoritesIds = ({ favorites, auth }) =>
  [
    ...(auth.me.uid
      ? favorites.resultsAuthenticated
      : favorites.resultsUnauthenticated),
  ].reverse();
export const selectFavoritesCount = ({ favorites, auth }) =>
  auth.me.uid
    ? favorites.resultsAuthenticated.length
    : favorites.resultsUnauthenticated.length;
export const selectIsUnfavoriteRequest = ({ favorites }) =>
  favorites.isUnfavoriteRequest;
